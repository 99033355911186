import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import BeersMenu from "../components/BeersMenu"
import Cookies from "js-cookie"
import BirthConsent from "../components/BirthConsent"
import Header from "../components/header"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import Footer from "../components/Footer"

const hasConfirmed = () => {
  return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
    || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};


export default class nuestrascervezas extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isScrolling: false,
      menuOpen: false,
      menuClass: 'slideOut',
      isDark: false,
      width: 1000,
      birthConsent: true,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    this.setState({
      width: window.innerWidth,
    })



    if (hasConfirmed()){

      this.handleConsent()
    }
    else {
      this.setState({
        birthConsent: false,
      })
    }
  }

  handleConsent = () =>{
    this.setState({
      birthConsent: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onMenuToggle = () =>{
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuClass: this.state.menuOpen ? 'slideOut' : 'slideIn',
      isDark: !this.state.menuOpen
    })
  };

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 200);

    var section = document.getElementsByTagName("SECTION");



    var bScroll = document.scrollingElement.scrollTop;
    console.log(bScroll)
    for (var i = 0; i < section.length; i++) {

      var sHeight = section[i].offsetHeight;
      var offsets = section[i].offsetTop;


      if (bScroll > offsets && bScroll < offsets + sHeight) {
        console.log(section[i].className)
        if (section[i].className !== 'holaHome'){
          this.setState({ isDark: true });
        }
        else if (section[i].className === 'holaHome') {
          this.setState({ isDark: false });
        }
      }/* else {
        section[i].className = "section";
      }*/


    }

  };

  render() {

    return (
      <div id={'nuestrasCervezas'}>
        <SEO title="Nuestras Cervezas" />
        {this.state.birthConsent &&
        <div>
          <Header isScrolling={this.state.isScrolling} onMenuToggle={this.onMenuToggle} isDark={this.state.isDark}
                  menuOpen={this.state.menuOpen} width={this.state.width}/>
          <BeersMenu/>
          {
            this.state.width > 767 ?
              <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
              :
              <MenuMobile onMenuToggle={this.onMenuToggle} menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
          }
          <Footer/>
        </div>

        }
        {!this.state.birthConsent &&
        <BirthConsent handleConsent={this.handleConsent}/>
        }
      </div>
    )
  }
}

